import React from 'react'
import { injectIntl } from 'react-intl';

import ClassHeader from '../ClassHeader';
import messages from './ErrorPage.messages';

const ErrorPage = ({ intl: { formatMessage } }) => (
    <section>
        <ClassHeader />
        <div className="awsui-util-p-l awsui-util-t-c">
            <h1 className="awsui-text-large">{formatMessage(messages.pageHeading)}</h1>
            <h2>{formatMessage(messages.pageNotFound)}</h2>
            <p>{formatMessage(messages.pageAdvice)}</p>
        </div>
    </section>
);

export default injectIntl(ErrorPage);
