import React, { useEffect } from 'react';
import _get from 'lodash/get';
import Loader from '../Loader';
import messages from './ClassPage.messages';
import ClassHeader from '../ClassHeader';
import ClassBanner from '../ClassBanner';
import ClassMain from '../ClassMain';
import ClassErrorModal from '../ClassErrorModal/ClassErrorModal';
import { useQuery } from '../utils';
import { useUser } from '../utils/user';
import { performFetch } from '../sagas';
import { useLangLocale } from '../LangLocaleProvider';

import './ClassPage.scss';

const ClassPage = ({ intl: { formatMessage }, classroomId }) => {
    const message = formatMessage(messages.classPageLongLoad);
    const { localeSet } = useLangLocale();
    const { idToken } = useUser();
    const { fetchDispatch, state } = useQuery('classData');
    const { data, error, loading } = state;
    const title = _get(data, 'course.title');

    const messageAfterSecondAndAHalf = error => {
        return error && error.retries > 4 && message;
    };

    const getClassroomData = () => {
        performFetch(fetchDispatch, {
            idToken,
            params: { path: `/${classroomId}` },
            api: state,
            retryOptions: {
                retries: 7,
                factor: 2,
                randomize: false,
                minTimeout: 200,
                maxTimeout: 3 * 1000,
                retryOnStatus: 403,
            },
        });
    };

    useEffect(() => {
        if (!idToken) return;
        getClassroomData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classroomId, idToken]);

    useEffect(() => {
        const classLangLocale = _get(data, 'classroom.langLocale');
        if (classLangLocale) {
            localeSet(classLangLocale);
        }
    }, [data, localeSet]);

    return (
        <Loader isLoading={loading} message={messageAfterSecondAndAHalf(error)}>
            <ClassHeader />
            {title && <ClassBanner title={title} />}
            <ClassMain classData={data} />
            {!!error && <ClassErrorModal error={error} />}
        </Loader>
    );
};

export default ClassPage;
