import React, { createContext, useContext, useState } from 'react';
import translations from './../i18n';
import { IntlProvider } from 'react-intl';

export const LangLocaleContext = createContext();

export const useLangLocale = () => {
    const ctx = useContext(LangLocaleContext);
    if (!ctx) {
        throw new Error('Set LangLocaleProvider first');
    }
    return ctx;
};

const LangLocaleProvider = ({ children, value }) => {
    const [locale, localeSet] = useState(value);
    const initialValue = { locale, localeSet };
    const messages = translations[locale];
    return (
        <LangLocaleContext.Provider value={initialValue}>
            <IntlProvider locale={locale} messages={messages}>
                {children}
            </IntlProvider>
        </LangLocaleContext.Provider>
    );
};

export default LangLocaleProvider;
