import { useState, useEffect, useContext } from 'react';
import { UserData } from 'react-oidc';
import _get from 'lodash/get';

export function useUser() {
    const userData = useContext(UserData);
    const [data, dataSet] = useState({});

    useEffect(() => {
        userData.userManager.getUser().then(dataSet);
    }, [userData]);

    return {
        idToken: data.id_token,
        userKey: _get(data, 'profile.hat_id'),
    };
}
