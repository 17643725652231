import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    awsTAndC: {
        id: 'header.awsTAndC',
        description: 'Name of aws organization',
        defaultMessage: 'AWS Training and Certification',
    },
});

export default messages;
