import React from 'react';
import { injectIntl } from 'react-intl';
import logo from './logo.svg';
import messages from './ClassHeader.messages';
import './ClassHeader.scss';

export const ClassHeader = ({ intl: { formatMessage } }) => (
    <header className="class-header">
        <img src={logo} alt={formatMessage(messages.awsTAndC)} />
    </header>
);

export default injectIntl(ClassHeader);
