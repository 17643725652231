import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import 'moment/min/locales';

import LabCard from '../LabCard';
import './LabList.scss';

const translateDurationUnit = (value, unit, locale) => {
    if (unit === 'min') {
        unit = 'minute';
    }
    return moment
        .duration(value, unit)
        .locale(locale)
        .humanize();
};

const LabList = ({
    intl: { locale },
    data,
    onClassClick,
    activeTrainings = new Set([]),
}) => {
    if (!data || !data.content) return null;

    const labList = data.course.content
        .filter(v => data.content[v])
        .map((id, i) => ({
            ...data.content[id],
            id,
            disabled: !activeTrainings.has(id),
            onClick: onClassClick.bind(null, id),
        }))
        .map(({ duration, durationUnit, ...rest }) => ({
            ...rest,
            duration: translateDurationUnit(duration, durationUnit, locale),
        }));

    return (
        <ol className="lab-list awsui-util-pl-n">
            {labList.map(lab => (
                <li key={lab.id}>
                    <LabCard {...lab} />
                </li>
            ))}
        </ol>
    );
};

export default injectIntl(LabList);
