import React from 'react';
import { Button, Icon } from '@amzn/awsui-components-react';
import { injectIntl } from 'react-intl';
import messages from '../LabList/LabList.messages';
import './LabCard.scss';

const LabCard = ({
    intl: { formatMessage },
    title,
    duration,
    description,
    disabled,
    onClick,
}) => {
    return (
        <div className="lab-card">
            <div className="lab-card__header">
                <h3>{title}</h3>
                <span className="lab-card__duration">
                    <Icon
                        className="lab-card__duration-icon"
                        name="status-pending"
                        variant="subtle"
                    />{' '}
                    {duration}
                </span>
            </div>

            <p>{description}</p>
            <div className="lab-card__actions">
                <Button disabled={disabled} onClick={onClick} variant="primary">
                    {disabled
                        ? formatMessage(messages.disabledLabBtnText)
                        : formatMessage(messages.enabledLabBtnText)}
                </Button>
            </div>
        </div>
    );
};

export default injectIntl(LabCard);
