import { fetchRequest, fetchError, fetchSuccess, fetchRetry } from '../actions';
import retry from 'retry';

import {
    AppError,
    FETCH_ERROR_NAME,
    AUTH_ERROR_TYPE,
    NOT_FOUND_ERROR_TYPE,
    SERVICE_ERROR_TYPE,
    INVALID_REQUEST_TYPE,
    NOT_AUTHORIZED_TYPE,
} from '../utils';

const getErrorType = statusCode => {
    switch (statusCode) {
        case 400:
            return INVALID_REQUEST_TYPE;
        case 401:
            return NOT_AUTHORIZED_TYPE;
        case 403:
            return AUTH_ERROR_TYPE;
        case 404:
            return NOT_FOUND_ERROR_TYPE;
        default:
            if (statusCode >= 500) return SERVICE_ERROR_TYPE;
            return undefined;
    }
};

export const performFetch = async (dispatch, opts = {}) => {
    const {
        api,
        idToken,
        params = {},
        retryOptions = {
            retries: 0,
        },
    } = opts;
    if (!idToken) {
        throw new AppError('"performFetch"" requires "idToken".', {
            type: AUTH_ERROR_TYPE,
        });
    }

    const { path = '', method = 'GET', headers = {}, body } = params;

    const {
        baseUrl,
        fetch: fetchFn = fetch,
        onSuccess = res => res.json && res.json(),
    } = api;

    dispatch(fetchRequest());

    const url = baseUrl + path;
    const preparedBody = body ? JSON.stringify(body) : undefined;
    const request = {
        method,
        retries: 5,
        retryDelay: 800,
        body: preparedBody,
        headers: {
            'Content-Type':
                method === 'POST'
                    ? 'application/x-www-form-urlencoded'
                    : 'application/json',
            Authorization: idToken,
            ...headers,
        },
    };

    const operation = retry.operation(retryOptions);

    operation.attempt(async retries => {
        try {
            const res = await fetchFn(url, request);

            //failure
            if (!res.ok) {
                // Retry
                if (
                    operation.retry(res.status === retryOptions.retryOnStatus)
                ) {
                    dispatch(
                        fetchRetry({
                            retries,
                            type: getErrorType(res.status),
                            name: FETCH_ERROR_NAME,
                            status: res.status,
                        })
                    );
                    return;
                }

                throw new AppError(
                    `Request to url ${url} rejected with status: ${res.status}`,
                    {
                        type: getErrorType(res.status),
                        name: FETCH_ERROR_NAME,
                        status: res.status,
                    }
                );
            }

            // Success
            const data = await onSuccess(res);
            dispatch(fetchSuccess(data));
        } catch (err) {
            dispatch(fetchError(err));
        }
    });
};
