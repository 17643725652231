import React from 'react';
import { Fragment } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import moment from 'moment-timezone';

import { formatTimezone, formatUTC, formatTime } from './metaFormatters';
import messages from './ClassMeta.messages';
import './ClassMeta.scss';

const MetaRowLabel = ({ children }) => (
    <dt className="class-meta-cell__title">{children}</dt>
);

export const LongDate = ({ time, timezone }) => {
    return (
        <Fragment>
            {formatTime(time)}
            <br />
            {`${formatTimezone(timezone)} ${formatUTC(time)}`}
        </Fragment>
    );
};

const LocationMetaRow = ({
    virtualUrl,
    locationType,
    physicalAddress,
    ariaLabel,
}) => {
    const { formatMessage } = useIntl();
    if (locationType === 'physical') {
        const { addressLine1, addressLine2, city, state, postalCode, country } =
            physicalAddress || {};

        return (
            <dd>
                {addressLine1} <br />
                {addressLine2} <br />
                {city}, {state} {postalCode}, {country}
            </dd>
        );
    } else {
        return (
            <dd className="overflowHiddenText">
                <a
                    href={virtualUrl}
                    className="overflowHiddenText"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="class-meta__virtual-class-link"
                    aria-label={ariaLabel}
                >
                    {formatMessage(messages.virtualClassLink)}
                </a>
            </dd>
        );
    }
};

export const ClassMeta = ({ intl: { formatMessage }, data, ...rest }) => {
    if (!data) return null;
    const { startsOn, endsOn, locationData, studentContext } = data.classroom;
    const { locationType, timezone, virtualUrl, physicalAddress } =
        locationData || {};

    const starts = moment.tz(startsOn * 1000, timezone);
    const ends = moment.tz(endsOn * 1000, timezone);

    const cellClassName = 'class-meta-cell';

    return (
        <div {...rest} className="class-meta">
            <dl>
                <div className={cellClassName}>
                    <MetaRowLabel>
                        {formatMessage(messages.physical)}
                    </MetaRowLabel>
                    <LocationMetaRow
                        locationType={locationType}
                        virtualUrl={virtualUrl}
                        physicalAddress={physicalAddress}
                        ariaLabel={formatMessage(messages.virtualLinkLabel)}
                    />
                </div>

                {startsOn && (
                    <div className={cellClassName}>
                        <MetaRowLabel>
                            {formatMessage(messages.starts)}
                        </MetaRowLabel>
                        <dd data-test="starts">
                            <LongDate time={starts} timezone={timezone} />
                        </dd>
                    </div>
                )}
                {endsOn && (
                    <div className={cellClassName}>
                        <MetaRowLabel>
                            {formatMessage(messages.ends)}
                        </MetaRowLabel>
                        <dd data-test="ends">
                            <LongDate time={ends} timezone={timezone} />
                        </dd>
                    </div>
                )}
                {studentContext && (
                    <div className={cellClassName}>
                        <MetaRowLabel>
                            {formatMessage(messages.studentNumber)}
                        </MetaRowLabel>
                        <dd data-test="student-context">{studentContext}</dd>
                    </div>
                )}
            </dl>
        </div>
    );
};

export default injectIntl(ClassMeta);
