import React from 'react';
import { injectIntl } from 'react-intl';
import './ClassBanner.scss';

export const ClassBanner = ({ title }) => (
    <div className="banner-header">
        <div data-test="title" className="banner-content">
            <h1 className="banner-title awsui-text-large">{title}</h1>
        </div>
    </div>
);

export default injectIntl(ClassBanner);
