import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    sectionTitle: {
        id: 'classLabListHeader.title.heading',
        description: 'Section title for the class lab list',
        defaultMessage: 'Labs',
    },
    labsInfo: {
        id: 'classLabListHeader.labs.info',
        description: 'Help text informing when labs will be enabled',
        defaultMessage: 'Labs will be made available by instructors',
    },
    callToActionButtonText: {
        id: 'classLabListHeader.callToAction.text',
        description: 'Text for the call to action button',
        defaultMessage: 'Student Guide',
    },
    labGuideButtonText: {
        id: 'classLabListHeader.labGuideButton.text',
        description: 'Text for the lab guidebutton',
        defaultMessage: 'Lab Guide',
    },
    closeModalButtonLabel: {
        id: 'classLabListHeader.studentGuideModal.closeModalButtonLabel',
        defaultMessage: 'Close dialog',
    },
    studentGuideLoadingTitle: {
        id: 'classLabListHeader.studentGuideModal.loadingTitle',
        description:
            'Title for the student guide modal when the code is still loading',
        defaultMessage: 'Retrieving Student Guide',
    },
    studentGuideLoadingContent: {
        id: 'classLabListHeader.studentGuideModal.loadingContent',
        description:
            'Content for the student guide modal when the code is still loading',
        defaultMessage: 'Your student guide is being prepared to download',
    },
    studentGuideSuccessContent: {
        id: 'classLabListHeader.studentGuideModal.successContent',
        description:
            'Content for the student guide modal when the code is successfully retrieved',
        defaultMessage: 'Your student guide is ready to download',
    },
    studentGuideErrorTitle: {
        id: 'classLabListHeader.studentGuideModal.errorTitle',
        description:
            'Title for the student guide modal when there was an error',
        defaultMessage: 'An error has occurred',
    },
    studentGuideErrorContent: {
        id: 'classLabListHeader.studentGuideModal.errorContent',
        description:
            'Content for the student guide modal when there was an error',
        defaultMessage: 'Please try again.',
    },
    studentGuideErrorModalActionButton: {
        id: 'classLabListHeader.studentGuideModal.errorActionButton',
        description: 'Text for the error modal button',
        defaultMessage: 'Close',
    },
});

export default messages;
