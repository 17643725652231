import environment from '../.config/environment';

const generateApi = (name, baseUrl, opts = {}) => ({
    [name]: {
        baseUrl,
        ...opts,
    },
});

const { HOST_NAME: { CLASSROOM } = {} } = environment(window);

export const hostnames = {
    CLASSROOM,
};

export const apis = {
    ...generateApi('classData', `${hostnames.CLASSROOM}/GetClassroom`),
    ...generateApi('useAccessCode', `${hostnames.CLASSROOM}/UseAccessCode`, {
        loading: false,
    }),
    ...generateApi(
        'createTraining',
        `${hostnames.CLASSROOM}/CreateTrainingSession`,
        { loading: false }
    ),
    ...generateApi('getTraining', `${hostnames.CLASSROOM}/GetTrainingSession`, {
        loading: false,
    }),
    ...generateApi(
        'getTrainingPermissions',
        `${hostnames.CLASSROOM}/GetTrainingPermissions`,
        { loading: false }
    ),
    ...generateApi('getGuides', `${hostnames.CLASSROOM}/GetGuidesLtiParams`, {
        loading: false,
    }),
    ...generateApi(
        'sendClientLog',
        `${hostnames.CLASSROOM}/SendClientLog/StudentUI`
    ),
    // DEPRECATED
    ...generateApi(
        'studentGuide',
        `${hostnames.CLASSROOM}/AssociateStudentGuide`,
        { loading: false }
    ),
};
