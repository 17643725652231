import moment from 'moment-timezone';
import uuid from 'uuid4';

export const isUndefined = val => val === undefined;

export const nowTime = () => Date.now();

export const numBetween = (lower, upper) =>
    Math.floor(Math.random() * (upper - lower + 1)) + lower;

export const isBeforeTimestamp = classStartTimestamp =>
    moment().isAfter(classStartTimestamp, nowTime());

export const adjustUnixTimestamp = unixTimestamp => unixTimestamp * 1000;

export const parseURIParams = uri =>
    uri.split('&').reduce((acc, set) => {
        const [key, val] = set.split('=');
        return { ...acc, [key]: val };
    }, {});

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const generateIdempotencyToken = () => uuid();

export const openTabBase = globals => url => {
    const newWindow = globals.open(url);
    newWindow.opener = null;
};

export const sleep = ms => {
    return new Promise(res => setTimeout(res, ms));
};

export const openTab = openTabBase(window);
