import { makeAuthenticator, makeUserManager } from 'react-oidc';

import environment from '../.config/environment';

export const LOCALE_KEY = 'langLocale';

export const configureAuth = ({ render, globals }) => {
    const {
        authorizer: { clientId, issuer },
    } = environment(globals);
    const beakerDevUserManagerParams = {
        client_id: clientId,
        authority: issuer,
        redirect_uri: `${globals.location.origin}/callback`,
        response_type: 'code',
        automaticSilentRenew: true,
    };
    const userManager = makeUserManager(beakerDevUserManagerParams);
    const Authenticator = makeAuthenticator({
        userManager: userManager,
    })(render);

    return {
        userManager,
        Authenticator,
    };
};
