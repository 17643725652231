import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from '@amzn/awsui-components-react';

import messages from './ClassErrorModal.messages';

const DEFAULT_ERROR = 'AppError';

const errorMessageTypes = {
    AuthError: {
        header: messages.authorizationErrorHeader,
        body: messages.authorizationErrorBody,
    },
    AppError: {
        header: messages.appErrorHeader,
        body: messages.appErrorBody,
    },
};

export const ClassErrorModal = ({ intl: { formatMessage }, error }) => {
    const errorMessage =
        error && errorMessageTypes[error.type]
            ? errorMessageTypes[error.type]
            : errorMessageTypes[DEFAULT_ERROR];
    const header = formatMessage(errorMessage.header);
    const message = formatMessage(errorMessage.body);

    return (
        <Modal
            visible
            data-test="app-modal"
            header={header}
            closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
        >
            {message}
        </Modal>
    );
};

export default injectIntl(ClassErrorModal);
